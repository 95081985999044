import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import Reactmarkdown from "react-markdown"
import SEO from "../../components/seo"

export const query = graphql`
  query KindergardenArticlesQuery {
    allStrapiArtykulyPrzedszkole(sort: { fields: published_at, order: DESC }) {
      edges {
        node {
          Tekst
          Tytul
          id
          published_at
        }
      }
    }
  }
`

function indexKindergarden({ data }) {
  const articles = data.allStrapiArtykulyPrzedszkole.edges.map(el => (
    <article key={el.node.id}>
      <h2>{el.node.Tytul}</h2>
      <Reactmarkdown
        transformImageUri={uri =>
          uri.startsWith("http") ? uri : `${process.env.IMAGE_BASE_URL}${uri}`
        }
        source={el.node.Tekst}
      />
    </article>
  ))

  return (
    <Layout menuType="kindergarden">
      <SEO title="Strona przedszkola - ZSP Nidek" />
      <h1>Strona przedszkola</h1>
      {articles}
    </Layout>
  )
}

export default indexKindergarden
